<template>
  <Teleport to="#luikit">
    <Box ref="drawerRef" class="drawer z-[9999] top-0 left-0" :class="drawerClasses">
      <input :id="id" :checked="modelValue" type="checkbox" class="drawer-toggle">
      <Box class="drawer-side">
        <label :for="id" class="drawer-overlay drawer-lasso-overlay" @click.prevent="closeModal" />
        <Box flex direction="col" class="bg-base-100 sm:p-12 p-4" :class="drawerContentClasses">
          <Box flex alignItems="center" justify="between" mb="7">
            <Typography variant="lato-h5" font="secondary">
              {{ title }}
            </Typography>
            <ButtonIcon icon="close" size="xs" variant="outlined" @click="closeModal" />
          </Box>
          <Box flexGrow="flex-1">
            <slot name="content" />
          </Box>
        </Box>
      </Box>
    </Box>
  </Teleport>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

import { useScrollLock } from '@lasso/shared/hooks'

import Typography from '../Typography/Typography.vue'

import Box from '../Box/Box.vue'

import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import { checkForUndefined } from '../../utils'

import { positions, sizes } from './classes'
import type { DrawerPositionType, DrawerPropsType, DrawerSizeType } from './types'

const props: DrawerPropsType = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  position: {
    type: String as PropType<DrawerPositionType>,
    default: 'end',
    validator: (position: DrawerPositionType) => !!positions[position],
  },
  size: {
    type: String as PropType<DrawerSizeType>,
    default: 'md',
    validator: (size: DrawerSizeType) => !!sizes[size],
  },
})

const emits = defineEmits(['update:modelValue', 'close', 'closed'])

const drawerRef = ref<InstanceType<typeof Box> | null>(null)

const showDrawer = ref(props.modelValue)

const closeModal = () => {
  emits('update:modelValue', false)
  emits('close')
}

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      showDrawer.value = true
    }
    else {
      setTimeout(() => {
        showDrawer.value = false
      }, 400)
    }
  },
)

useScrollLock(showDrawer)

const drawerClasses = computed(() => {
  const position = checkForUndefined(props.position, positions)
  return {
    [position]: !!props.position,
    'fixed': showDrawer.value,
    'h-0': !showDrawer.value,
  }
})

const drawerContentClasses = computed(() => {
  const size = checkForUndefined(props.size, sizes)

  return {
    [size]: props.size,
  }
})

const handleAnimationEnd = useDebounceFn(() => {
  if (!showDrawer.value) {
    emits('closed')
  }
}, 100)

onMounted(() => {
  drawerRef.value?.$el?.addEventListener('transitionend', handleAnimationEnd)
})

onBeforeUnmount(() => {
  drawerRef.value?.$el?.removeEventListener('transitionend', handleAnimationEnd)
})
</script>

<style scoped src="./drawer.styles.css"> </style>
