<template>
  <Box>
    <Box v-if="title" pb="4" flex alignItems="center" justify="between" spaceX="2" spaceY="2" wrap="wrap">
      <InputLabel :id="id" variant="h6" color="textPrimary" :required="required">
        {{ title }}
      </InputLabel>
      <Box v-if="$slots.actions" flex spaceX="2" spaceY="2" wrap="wrap" alignItems="center">
        <slot name="actions" />
      </Box>
    </Box>

    <slot :id="id" />

    <Transitions>
      <FormError :id="id" pt="1" :errorText="errorText" />
    </Transitions>
  </Box>
</template>

<script setup lang="ts">
import Box from '../Box/Box.vue'
import InputLabel from '../Input/InputLabel/InputLabel.vue'
import FormError from '../FormError/FormError.vue'
import Transitions from '../Transitions/Transitions.vue'

withDefaults(defineProps<{
  id?: string
  title?: string
  required?: boolean
  errorText?: string
}>(), {
  id: '',
  errorText: '',
})
</script>
