<template>
  <transition :name="name" :mode="mode">
    <div v-if="uniqueKey !== undefined" :key="uniqueKey">
      <slot />
    </div>

    <slot v-else />
  </transition>
</template>

<script lang="ts" setup>
import { TransitionsMode, TransitionsName } from './types'

withDefaults(defineProps<{
  name?: TransitionsName
  mode?: TransitionsMode
  uniqueKey?: PropertyKey
}>(), {
  name: 'fade',
  mode: 'default',
})
</script>

<style scoped src="./transitions.style.css" />
