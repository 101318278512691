<template>
  <VirtualScroller
    v-slot="slotProps"
    class="select-panel-virtual-scroller"
    :items="items"
    :itemSize="34"
    :keyField="keyField as string"
    itemClass="scroller-item"
  >
    <slot v-bind="slotProps" />
  </VirtualScroller>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { KeyOf } from '@lasso/shared/types'

import { VirtualScroller } from '../VirtualScroller'

defineProps<{
  items: T[]
  keyField: KeyOf<T>
}>()
</script>

<style scoped>
.select-panel-virtual-scroller :deep(.scroller-item) {
  @apply border-b border-base-500 last-of-type:border-b-0;
}
</style>
