<template>
  <Box flex spaceX="2" justify="between" alignItems="center">
    <Skeleton height="1.5rem" width="100px" />
    <Box flex spaceX="2">
      <Skeleton v-if="pageSizes" height="1.5rem" width="155px" />
      <Skeleton height="1.5rem" width="200px" />
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { Box } from '../Box'
import { Skeleton } from '../Skeleton'

defineProps<{
  pageSizes: boolean
}>()
</script>
