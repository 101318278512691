<template>
  <Teleport to="#luikit">
    <Box
      ref="modalRef"
      component="label"
      class="modal modal-bottom sm:modal-middle modal-lasso-overlay"
      :class="classes"
      :for="id"
    >
      <Box ref="modalBoxRef" class="modal-box relative" :class="modalBoxClasses" :maxWidth="maxWidth">
        <Box flex direction="col" class="overflow-auto">
          <slot name="header" v-bind="slotBindings">
            <Box v-if="title" pb="4" flex alignItems="center" direction="row" justify="between" spaceX="1">
              <Typography variant="h6" color="textPrimary">
                {{ title }}
              </Typography>

              <ButtonIcon v-if="!hideCloseButton" icon="close" @click="closeModal" />
            </Box>
          </slot>

          <Box>
            <Typography variant="body1" color="textSecondary">
              <slot v-bind="slotBindings" />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Teleport>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { onClickOutside, useDebounceFn } from '@vueuse/core'

import { v4 as uuidv4 } from 'uuid'

import { useScrollLock } from '@lasso/shared/hooks'

import Box from '../Box/Box.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Typography from '../Typography/Typography.vue'

import { checkForUndefined } from '../../utils'

import type { ModalPropsType, ModalSizeType } from './types'

import { sizes } from './classes'

const props: ModalPropsType = defineProps({
  opened: {
    type: Boolean,
    default: null,
  },
  size: {
    type: String as PropType<ModalSizeType>,
    default: 'md',
    validator: (size: ModalSizeType) => !!sizes[size],
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
  disablePadding: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['close', 'closed'])

const id = ref(`modal-${uuidv4()}`)
const modalRef = ref<InstanceType<typeof Box> | null>(null)
const modalBoxRef = ref<InstanceType<typeof Box> | null>(null)

const isShowAnimationInProgress = ref(false)
const classes = computed(() => {
  // This z-index trick is required to deal with Tippy overlapping.
  // Commonly, the Tippy z-index should be higher than anything else (e.g. for correctly displaying the Select dropdown placed in the Modal).
  // But during the transition of the Modal, the underlying Tippy z-index should be lower than the Modal z-index,
  // while the modal is in showing and lover level Tippy elements are in closing phases.
  // Be careful with editing this values, because it can break the Tippy z-index.
  const zIndexClasses = {
    '!z-[999999]': isShowAnimationInProgress.value,
    '!z-[9999]': !isShowAnimationInProgress.value,
  }

  return {
    'modal-open': props.opened,
    ...zIndexClasses,
  }
})

const modalBoxClasses = computed(() => {
  return {
    'p-0': props.disablePadding,
  }
})

const closeModal = () => {
  emits('close')
}

const keyboardHandler = (event: KeyboardEvent) => {
  if (props.opened && event.code?.toLowerCase() === 'escape') {
    closeModal()
  }
}

onMounted(() => {
  window.addEventListener('keydown', keyboardHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', keyboardHandler)
})

onClickOutside(modalBoxRef, () => {
  if (props.opened) {
    closeModal()
  }
})

const slotBindings = {
  closeModal,
}

const maxWidth = computed(() => {
  return `${checkForUndefined(props.size, sizes)}`
})

const handleAnimationStart = useDebounceFn(() => {
  if (props.opened) {
    isShowAnimationInProgress.value = true
  }
}, 100)

const handleAnimationEnd = useDebounceFn(() => {
  if (props.opened) {
    setTimeout(() => {
      isShowAnimationInProgress.value = false
    }, 500)
  }

  if (!props.opened) {
    emits('closed')
  }
}, 100)

useScrollLock(() => props.opened || isShowAnimationInProgress.value)

onMounted(() => {
  modalBoxRef.value?.$el?.addEventListener('transitionend', handleAnimationEnd)
  modalBoxRef.value?.$el?.addEventListener('transitionstart', handleAnimationStart)
})

onBeforeUnmount(() => {
  modalBoxRef.value?.$el?.removeEventListener('transitionend', handleAnimationEnd)
  modalBoxRef.value?.$el?.removeEventListener('transitionstart', handleAnimationStart)
})
</script>

<style scoped src="./modal.styles.css"> </style>
