import { computed, reactive, ref } from 'vue'
import { createDeferred } from '@lasso/shared/utils'
import { ComponentProps } from '@lasso/shared/types'

import ModalConfirm from './ModalConfirm.vue'

export type UseModalConfirmOptions<T> = {
  onConfirm?: () => Promise<T>
} & Partial<Pick<ComponentProps<typeof ModalConfirm>, 'title' | 'subtitle' | 'confirmText' | 'cancelText' | 'size'>>

export const useModalConfirm = () => {
  const defaultProps = {
    title: 'Are you sure?',
    subtitle: '',
  }

  const modalPropsInternal = reactive({})

  const modelValue = ref(false)
  const loading = ref(false)

  let deferred: ReturnType<typeof createDeferred<boolean>> | null = null

  const handleClose = () => {
    if (deferred) {
      deferred.resolve(false)
    }
  }

  const handleConfirm = () => {
    if (deferred) {
      deferred.resolve(true)
    }
  }

  const modalProps = computed((): ComponentProps<typeof ModalConfirm> => {
    return {
      ...defaultProps,
      ...modalPropsInternal,
      'loading': loading.value,
      'modelValue': modelValue.value,
      'onUpdate:modelValue': handleClose,
      'onConfirm': handleConfirm,
    }
  })

  const confirm = async <T = true>({
    onConfirm, ...props
  }: UseModalConfirmOptions<T>): Promise<T | false> => {
    if (deferred) {
      return false
    }

    Object.assign(modalPropsInternal, props)

    deferred = createDeferred<boolean>()

    modelValue.value = true

    const result = await deferred.promise
    deferred = null

    if (!result) {
      modelValue.value = false
      return false
    }

    if (!onConfirm) {
      modelValue.value = false
      return true as T
    }

    loading.value = true
    try {
      return await onConfirm()
    }
    finally {
      loading.value = false
      modelValue.value = false
    }
  }

  return { modalProps, confirm }
}
