<template>
  <Modal :size="size" :opened="modelValue" :title="title" @close="handleClose()" @closed="handleClosed()">
    <Box flex direction="col" spaceY="4">
      <Typography v-if="subtitle" variant="body1" color="textSecondary">
        {{ subtitle }}
      </Typography>

      <Box flex direction="row" spaceX="2" justify="end">
        <Button variant="outlined" color="primary" size="md" :disabled="loading" @click="handleCancel()">
          {{ cancelText }}
        </Button>
        <Button color="primary" size="md" :disabled="loading" :loading="loading" @click="handleConfirm()">
          {{ confirmText }}
        </Button>
      </Box>
    </Box>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '../Modal/Modal.vue'
import { ModalSizeType } from '../Modal/types'
import Button from '../Button/Button.vue'
import Box from '../Box/Box.vue'
import Typography from '../Typography/Typography.vue'

withDefaults(defineProps<{
  modelValue: boolean
  title: string
  subtitle?: string
  confirmText?: string
  cancelText?: string
  loading: boolean
  size?: ModalSizeType
}>(), {
  subtitle: '',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  size: 'sm',
})

const emit = defineEmits<{
  confirm: []
  cancel: []
  closed: []
  'update:modelValue': [boolean]
}>()

const handleClose = () => {
  emit('update:modelValue', false)
}

const handleClosed = () => {
  emit('closed')
}

const handleCancel = () => {
  emit('cancel')
  emit('update:modelValue', false)
}

const handleConfirm = () => {
  emit('confirm')
}
</script>
