import { DrawerSizeType } from './types'

export const positions = {
  end: 'drawer-end',
}

export const sizes: Record<DrawerSizeType, string> = {
  xs: 'sm:w-60',
  sm: 'sm:w-80',
  md: 'sm:w-96',
  lg: 'sm:w-[576px]',
  xl: 'md:w-[874px]',
}
