<template>
  <Box flex justify="start" alignItems="center" spaceX="1">
    <Icon v-if="isError" icon="info" size="xs" color="error" />

    <Typography variant="caption1" :color="isError ? 'error' : 'textSecondaryLight'">
      <slot />
    </Typography>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import Typography from '../Typography/Typography.vue'

const props = defineProps<{
  variant?: 'error'
}>()

const isError = computed(() => props.variant === 'error')
</script>
