<template>
  <Typography class="select-placeholder" truncate variant="body2" color="textSecondaryLight">
    <slot />
  </Typography>
</template>

<script lang="ts" setup>
import Typography from '../Typography/Typography.vue'
</script>

<style scoped>
.select-placeholder {
  @apply select-none;
}
</style>
