<template>
  <DynamicVirtualScroller
    v-slot="slotProps"
    class="select-panel-virtual-scroller"
    :items="items"
    :minItemSize="36"
    :keyField="keyField as string"
    itemClass="scroller-item"
  >
    <DynamicVirtualScrollerItem :item="slotProps.item" :active="slotProps.active">
      <slot v-bind="slotProps" />
    </DynamicVirtualScrollerItem>
  </DynamicVirtualScroller>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { KeyOf } from '@lasso/shared/types'

import { DynamicVirtualScroller, DynamicVirtualScrollerItem } from '../VirtualScroller'

defineProps<{
  items: T[]
  keyField: KeyOf<T>
}>()
</script>

<style scoped>
.select-panel-virtual-scroller :deep(.scroller-item) {
  @apply border-b border-base-500 last-of-type:border-b-0;
}
</style>
