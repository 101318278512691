import { MaybeRefOrGetter, Ref, computed, ref, toValue } from 'vue'

import { KeyOf, RefOrGetter } from '@lasso/shared/types'

import { compareByOrder } from '@lasso/shared/utils'

import { TableColumn, TableColumns, TableSearchEmit, TableSorting } from './types'

export const useTableFields = <T extends Record<string, unknown>, K extends KeyOf<T> | '' = KeyOf<T> | ''>({
  fields: fieldsConfig,
  enabledFields,
  fieldsOrder,
  sorting = ref({ sortColumn: '', sortDirection: '' }) as Ref<TableSorting<K>>,
  search = ref({}),
}: {
  fields: MaybeRefOrGetter<TableColumns<T>>
  enabledFields?: RefOrGetter<Array<keyof T>>
  fieldsOrder?: RefOrGetter<Array<keyof T>>
  sorting?: Ref<TableSorting<K>>
  search?: Ref<Partial<Record<K, string>>>
}) => {
  const withHidden = (field: TableColumn<T>): TableColumn<T> => ({
    ...field,
    hidden: enabledFields ? !toValue(enabledFields).includes(field.id) : field.hidden,
    search: search.value?.[field.id] ?? '',
  })

  const fields = computed((): TableColumns<T> => {
    const fields = toValue(fieldsConfig).map(withHidden)

    if (fieldsOrder) {
      fields.sort((a, b) => compareByOrder(a.id, b.id, toValue(fieldsOrder)))
    }

    return fields
  })

  const handleSearch = (event: TableSearchEmit<T>) => {
    search.value = {
      ...search.value,
      [event.column]: event.search,
    }
  }

  return {
    fields,
    sorting,
    search,
    handleSearch,
  }
}
