<template>
  <Link color="secondary" class="inline-block">
    <Box component="span" flex alignItems="center" spaceX="1">
      <Icon icon="add" />
      <Typography variant="body2">
        <slot />
      </Typography>
    </Box>
  </Link>
</template>

<script setup lang="ts">
import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import Link from '../Link/Link.vue'
import Typography from '../Typography/Typography.vue'
</script>
