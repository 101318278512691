<template>
  <Card :withDivider="withDivider">
    <template #headerTitle>
      <Typography variant="lato-h6">
        <slot name="title">
          {{ title }}
        </slot>
      </Typography>
    </template>
    <template #headerActions>
      <slot name="headerActions" />
    </template>
    <Box flex col mt="4">
      <slot />
    </Box>
  </Card>
</template>

<script lang="ts" setup>
import Box from '../../Box/Box.vue'
import Card from '../../Card/Card.vue'
import Typography from '../../Typography/Typography.vue'

defineProps({
  title: {
    type: String,
    default: '',
  },
  withDivider: {
    type: Boolean,
    default: true,
  },
})
</script>
