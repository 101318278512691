<template>
  <Paper color="secondary-light" variant="filled" shadow="none" rounded="xl" px="6" py="4" flex col spaceY="4">
    <slot v-if="$slots.header || title" name="header">
      <Typography variant="caption1" color="inherit">
        {{ title }}
      </Typography>
    </slot>
    <ProgressBar v-if="progress !== undefined" :progress="progress" color="secondary" />
    <Box v-if="$slots.default">
      <slot />
    </Box>
    <Box v-if="$slots.left || $slots.right" flex row spaceX="2" justify="between">
      <Box v-if="$slots.left">
        <slot name="left" />
      </Box>
      <Box v-if="$slots.right" alignItems="end" flex col>
        <slot name="right" />
      </Box>
    </Box>
  </Paper>
</template>

<script lang="ts" setup>
import Box from '../Box/Box.vue'

import Paper from '../Paper/Paper.vue'
import ProgressBar from '../ProgressBar/ProgressBar.vue'
import Typography from '../Typography/Typography.vue'

withDefaults(defineProps<{
  title?: string
  progress?: number
}>(), {
  title: '',
})
</script>
