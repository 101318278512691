<template>
  <InputWrapper
    :disabled="disabled"
    :focused="focused"
    :error="inputError"
    :withValue="withValue"
    :variant="variant"
    :size="size"
    @click.prevent="inputRef?.focus()"
  >
    <InputIcon :icon="icon" :endIcon="endIcon" :disabled="disabled">
      <Box flex spaceX="1" spaceY="1" wrap="wrap" alignItems="center" minWidth="0">
        <Chip
          v-for="(value, index) in modelValueInternal"
          :key="index"
          size="3xs"
          class="max-w-full"
          :cancelable="!readonly"
          :disabled="disabled"
          @cancel="onDeleteClick(index)"
          @click.stop
        >
          <span class="truncate">
            {{ value }}
          </span>
        </Chip>
        <input
          :id="id"
          ref="inputRef"
          v-model="chip"
          class="input-chips"
          :style="{ width: inputWidth }"
          :name="name"
          :disabled="disabled"
          :readonly="readonly"
          :autocomplete="autocomplete"
          :data-test-id="dataTestId"
          :placeholder="placeholderInternal"
          type="text"
          @keydown="onKeyDown($event)"
          @focus="onFocus($event)"
          @blur="onBlur($event)"
        >
      </Box>
    </InputIcon>
  </InputWrapper>
</template>

<script setup lang="ts">
import { useFocus } from '@vueuse/core'
import { type Ref, computed, inject, nextTick, ref } from 'vue'
import { truthy } from '@lasso/shared/utils'
import { uniq } from 'lodash-es'

import { InputAutocomplete, InputIcon, InputWrapper, InputWrapperSize, InputWrapperVariant } from '../Input'
import { Box } from '../Box'
import { Chip } from '../Chip'

const props = withDefaults(
  defineProps<{
    modelValue: string[]
    name?: string
    id?: string
    variant?: InputWrapperVariant
    size?: InputWrapperSize
    placeholder?: string
    disabled?: boolean
    dataTestId?: string
    readonly?: boolean
    error?: boolean
    icon?: string
    endIcon?: string
    autocomplete?: InputAutocomplete
    allowSpace?: boolean
  }>(),
  {
    name: '',
    id: '',
    variant: 'default',
    size: 'lg',
    disabled: false,
    readonly: false,
    error: false,
    placeholder: '',
    autocomplete: 'on',
    allowSpace: false,
  },
)

const emit = defineEmits<{
  'update:modelValue': [unknown]
  focus: [Event]
  blur: [Event]
}>()

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', uniq(value))
  },
})

const inputRef = ref<HTMLInputElement>()
const { focused } = useFocus(inputRef)

const formControlHasError = inject<Ref<boolean>>('formControlHasError', ref(false))
const inputError = computed(() => formControlHasError.value || props.error)

const chip = ref('')
const chipValues = computed(() => {
  const value = chip.value.trim()
  const values = props.allowSpace ? [value] : value.split(' ')

  return values.filter(truthy)
})
const withValue = computed(() => modelValueInternal.value.length > 0 || Boolean(chip.value))
const inputWidth = computed(() => withValue.value ? `${chip.value.length + 4}ch` : '100%')
const placeholderInternal = computed(() => withValue.value ? '' : props.placeholder)

const onKeyDown = (event: KeyboardEvent): void => {
  const values = chipValues.value

  if (event.key === 'Escape') {
    chip.value = ''
  }

  if (values.length > 0 && event.key === 'Enter') {
    event.preventDefault()
    event.stopPropagation()
  }

  if ((!props.allowSpace && event.key === ' ') || event.key === 'Enter') {
    if (values.length > 0) {
      modelValueInternal.value = [...props.modelValue, ...values]

      nextTick(() => {
        chip.value = ''
      })
    }
  }

  if (event.key === 'Backspace') {
    if (values.length === 0) {
      modelValueInternal.value = props.modelValue.slice(0, -1)
    }
  }
}

const onBlur = (event: Event): void => {
  const values = chipValues.value

  if (values.length > 0) {
    modelValueInternal.value = [...props.modelValue, ...values]
  }

  chip.value = ''

  emit('blur', event)
}

const onFocus = (event: Event) => {
  emit('focus', event)
}

const onDeleteClick = (index: number): void => {
  modelValueInternal.value = modelValueInternal.value.toSpliced(index, 1)
}
</script>

<style scoped>
.input-chips {
  @apply
    text-14 tracking-15 /* Values of Typography Body2 */
    text-textPrimary
    placeholder:text-textSecondaryLight
    grow
    h-[18px];
}
</style>
