<template>
  <Select
    ref="selectRef"
    :hideOnClick="false"
    :options="localOptions"
    :modelValue="modelValueInternal as SelectModelValueType"
    :multiple="multiple"
    :highlight="searchQueryInternal"
    :noOptionsMessage="noOptionsMessage"
    :loading="loading && !loaded"
    :loadingMore="canLoadMore"
    v-bind="$attrs"
    class="autocomplete"
    :class="{ loading: search && loading, multiple }"
    :hideDropdown="shouldHideDropdown"
    :withValue="withValue"
    :objectValue="objectValue"
    @selectOption="selectOption"
    @hide="onHide"
  >
    <template #trigger="{ disabled, open, deleteOption, selectedOptions }">
      <InputText
        v-model="searchQueryInternal"
        :disabled="disabled"
        :endIcon="search && loading ? 'autorenew' : ''"
        :placeholder="placeholder"
        :icon="icon"
        :error="error"
        :variant="variant"
        disableValidation
        class="autocomplete-input"
        :dataTestId="dataTestId"
        @update:modelValue="onSearchQueryInput"
        @focus="open"
        @click="open"
      >
        <template v-if="multiple && !hideSelectedValues" #inputBefore>
          <SelectValue multiple :options="selectedOptions" :disabled="disabled" @cancel="(option) => clearOption(option, deleteOption)" />
        </template>
      </InputText>
    </template>
    <template #dropdown>
      <slot name="dropdown" />
    </template>
    <template v-if="search" #infiniteScroll>
      <InfiniteScroll mt="1" :fetchNextPage="fetchOptions" :disabled="!canLoadMore || options.length === 0" />
    </template>
  </Select>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { useVModel, whenever } from '@vueuse/core'

import InputText from '../Input/InputText/InputText.vue'

import Select from '../Select/Select.vue'
import SelectValue from '../Select/SelectValue.vue'

import type { SelectModelValueType, SelectOptionType, SelectOptionValueType } from '../Select/types'
import type { InputWrapperVariant } from '../Input/InputWrapper/types'
import { InfiniteScroll } from '../InfiniteScroll'

const props = withDefaults(defineProps<{
  modelValue?: SelectModelValueType
  search?: () => Promise<unknown>
  searchQuery?: string
  placeholder?: string
  options: SelectOptionType[]
  multiple?: boolean
  hideSelectedValues?: boolean
  icon?: string
  error?: boolean
  variant?: InputWrapperVariant
  dataTestId?: string
  noOptionsMessage?: string
  withValue?: boolean
  objectValue?: boolean
  loading?: boolean
  canLoadMore?: boolean
}>(), {
  modelValue: '',
  placeholder: '',
  multiple: false,
  hideSelectedValues: false,
  icon: '',
  variant: 'default',
  withValue: false,
  objectValue: false,
  loading: false,
  canLoadMore: false,
  searchQuery: '',
})

const emits = defineEmits(['update:modelValue', 'update:searchQuery'])

const modelValueInternal = useVModel(props, 'modelValue', emits)
const searchQueryInternal = useVModel(props, 'searchQuery', emits, { passive: true })
const searchQueryNormalized = computed(() => searchQueryInternal.value.trim().toLowerCase())
const selectRef = ref<InstanceType<typeof Select> | null>(null)
const loaded = ref(false)
const optionsLoaded = ref(false)

const shouldHideDropdown = computed(() => {
  if (props.search) {
    return !optionsLoaded.value || (props.options.length === 0 && props.loading)
  }
  return false
})

const clearOption = (option: SelectOptionType, callback: (option: SelectOptionType) => void) => {
  if (props.multiple && Array.isArray(modelValueInternal.value)) {
    modelValueInternal.value = modelValueInternal.value.filter(item => item !== option.value)
  }
  else {
    modelValueInternal.value = ''
  }

  callback(option)
}

const fetchOptions = async () => {
  await props.search?.()
}

const localOptions = computed((): SelectOptionType[] => {
  if (props.search) {
    return props.options
  }

  const query = searchQueryNormalized.value

  return props.options?.filter((option) => {
    return option.label.toLowerCase().includes(query) || String(option.value).toLowerCase().includes(query)
  })
})

const hasSelection = computed(() => {
  if (props.multiple && Array.isArray(modelValueInternal.value)) {
    return Boolean(modelValueInternal.value?.length)
  }
  else {
    return Boolean(modelValueInternal.value)
  }
})

const isObjectValue = (value: SelectOptionValueType | SelectOptionType): value is SelectOptionType<any> => {
  return Boolean(value) && typeof value === 'object'
}

const getOptionValue = (optionValue: SelectOptionType['value']) => {
  return isObjectValue(optionValue) ? optionValue.value : optionValue
}

const onHide = () => {
  // clear selection if the search query was changed without changing the selection
  if (hasSelection.value && !props.multiple) {
    const selectedItem = localOptions.value.find((item) => {
      return getOptionValue(item.value) === getOptionValue(modelValueInternal.value as SelectOptionValueType)
    })
    if (selectedItem && searchQueryNormalized.value !== selectedItem?.label.trim().toLowerCase()) {
      modelValueInternal.value = ''
    }
  }

  if (!hasSelection.value) {
    searchQueryInternal.value = ''
  }
}

const scrollToTop = () => {
  selectRef.value?.scrollToTop?.()
}

const onSearchQueryInput = (newValue: string) => {
  scrollToTop()

  if (!props.multiple && newValue === '' && hasSelection.value) {
    modelValueInternal.value = ''
  }

  if (props.search) {
    if (newValue) {
      // do no search if we just select already loaded value
      const valueInOptions = props.options.map(item => item.label).includes(searchQueryInternal.value)
      if (!valueInOptions) {
        selectRef.value?.open()
      }
    }
  }
  else {
    selectRef.value?.open()
  }
}

const selectOption = async (option: SelectOptionType) => {
  const label = String(option.label || option.value)

  if (props.multiple && Array.isArray(modelValueInternal.value)) {
    const selectedItem = modelValueInternal.value.find((item) => {
      return (isObjectValue(item) ? item.value : item) === getOptionValue(option.value)
    })
    if (selectedItem) {
      modelValueInternal.value = modelValueInternal.value.filter(item => item !== selectedItem)
    }
    else {
      modelValueInternal.value = [
        ...modelValueInternal.value,
        props.objectValue ? option : option.value,
      ]
    }
  }
  else {
    modelValueInternal.value = option.value
  }

  if (props.multiple) {
    searchQueryInternal.value = ''
  }
  else {
    searchQueryInternal.value = label
  }
}

watch(() => props.modelValue, () => {
  if (props.multiple) {
    return
  }

  if (!props.modelValue) {
    searchQueryInternal.value = ''
    return
  }

  const option = props.options.find((item) => {
    return (isObjectValue(item) ? item.value : item) === getOptionValue(props.modelValue as SelectOptionValueType)
  })
  if (option) {
    searchQueryInternal.value = String(option.label || option.value)
  }
}, { immediate: true })

watch(() => props.options, () => {
  if (props.multiple) {
    return
  }

  if (!props.modelValue) {
    return
  }

  const option = props.options.find((item) => {
    return (isObjectValue(item) ? item.value : item) === getOptionValue(props.modelValue as SelectOptionValueType)
  })

  if (option) {
    selectOption(option)
  }
}, { immediate: true })

whenever(() => !props.loading, () => {
  optionsLoaded.value = true
}, { immediate: !props.search || props.options.length > 0 })

whenever(() => !props.loading, () => {
  loaded.value = true
}, { immediate: true })

defineExpose({ selectOption })
</script>

<style scoped>
.autocomplete.loading :deep(.end-input-icon) {
  @apply animate-spin;
}

.autocomplete.multiple :deep(.input-icon-inner) {
  @apply flex-wrap;
}

.autocomplete-input :deep(input) {
  @apply w-full truncate;
}
</style>
