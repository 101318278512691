<template>
  <Card disablePY py="4">
    <template #headerTitle>
      <Typography variant="lato-h6">
        <slot name="title">
          {{ title }}
        </slot>
      </Typography>
    </template>
    <template #headerActions>
      <slot name="headerActions" />
    </template>
    <Box flex col mt="4">
      <slot />
    </Box>

    <template #footer>
      <slot name="footer" />
    </template>
  </Card>
</template>

<script lang="ts" setup>
import Box from '../../Box/Box.vue'
import Card from '../../Card/Card.vue'
import Typography from '../../Typography/Typography.vue'

defineProps({
  title: {
    type: String,
    default: '',
  },
})
</script>
