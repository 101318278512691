<template>
  <Box class="select-panel" :class="{ minHeight }">
    <Box class="column-left">
      <slot name="search" />

      <Box v-if="$slots.tabs" class="select-panel-tabs">
        <slot name="tabs" />
      </Box>

      <Box v-if="empty" flex justify="center" alignItems="center" grow="grow">
        <EmptyState :icon="emptyIcon" :message="placeholder" />
      </Box>
      <Box v-else :key="listKey" class="select-panel-items" grow="grow">
        <slot name="items" />

        <Box v-if="loading" component="footer" flex justify="center" alignItems="center" grow="grow" minHeight="50px">
          <CircularProgress size="lg" color="secondary" />
        </Box>
      </Box>

      <Box v-if="$slots.pagination" class="select-panel-pagination">
        <slot name="pagination" />
      </Box>
    </Box>

    <Box v-if="$slots.selectedItems" class="column-right">
      <Box v-if="$slots.selectedItemsTitle" class="selected-items-title">
        <Typography variant="subtitle2" color="textPrimary">
          <slot name="selectedItemsTitle" />
        </Typography>
      </Box>
      <Box class="selected-items">
        <slot name="selectedItems" />
      </Box>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import Box from '../Box/Box.vue'
import Typography from '../Typography/Typography.vue'
import CircularProgress from '../CircularProgress/CircularProgress.vue'
import EmptyState from '../EmptyState/EmptyState.vue'

withDefaults(defineProps<{
  minHeight?: boolean
  loading?: boolean
  empty?: boolean
  emptyIcon?: string
  placeholder?: string
  listKey?: string | number | symbol
}>(), {
  minHeight: false,
  loading: false,
  empty: false,
  placeholder: 'Nothing was found',
  listKey: '',
})
</script>

<style scoped>
.select-panel {
  @apply flex flex-row gap-px flex-wrap bg-base-500 border border-base-500;
}

.select-panel.minHeight > :is(.column-left, .column-right) {
  @apply min-h-[409px];
}

.column-right {
  @apply overflow-y-auto p-4 min-w-[300px] max-h-[409px];
}

.column-left {
  @apply flex flex-col max-h-[409px];
  min-width: min(100%, 439px);
}

.column-left,
.column-right {
  @apply grow basis-0 bg-base-100;
}

.select-panel-items {
  @apply flex flex-col overflow-y-auto;
}

.select-panel-tabs {
  @apply px-4 py-2 border-b border-base-500;
}

.selected-items {
  @apply flex flex-col gap-y-2;
}

.selected-items-title {
  @apply pb-4;
}

.select-panel-pagination {
  @apply flex justify-end border-t border-base-500 py-1;
}
</style>
